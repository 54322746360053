@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap');
footer {
	color: #212529;
	position:relative;
	width: 92%;
	border-radius: 0.5em;
	background-color: rgba(255, 255, 255, 0.55);
    text-align: center;
    font-size: 14px;
	display :flex;
    flex-direction: row;
	justify-content:space-between;
}
.nazwa table{
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}
table{
	margin-left: auto;
	margin-right: auto;
	text-align: left;
}
.nazwa
{
	text-align: center;
	margin: 5px;
	border-radius: 0.5em;
	font-family: 'Manrope', sans-serif;
	width: 40%;
}
.dane
{
	text-align: center;
	margin: 5px;
	border-radius: 0.5em;
	font-family: 'Manrope', sans-serif;
	width: 40%;
}
p{
    margin: 0px;
}
.ghost{
	color: #212529;
	position:relative;
	width: 92%;
	border-radius: 0.5em;
	background-color: rgba(255, 255, 255, 0.55);
    text-align: center;
    font-size: 14px;
	display :flex;
    flex-direction: row;
	justify-content:normal;
	visibility: hidden;
}