@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap');

nav{
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.175);
    max-width: 1300px;
    margin: 15px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.5em;
    padding: 5px;
    flex-direction: column;
    font-family: 'Manrope', sans-serif;
    background-color: white;
}
nav img{
    margin: 12px;
    margin-right: auto;
}
.navbar-toggler collapsed, .navbar-toggler{
    margin-left: auto;
}
.container img{
    width: 32px;
    height: 32px;
    margin: 0px;
}