@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600&display=swap');
.article{
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.175);
    max-width: 1300px;
    margin: 15px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.5em;
    padding: 12px;
    flex-direction: column;
    font-family: 'Manrope', ;
    background-color: white;
}
.title{
    font-size: 32px;
    text-align: center;
}
.subtitle{
    font-size: 24px;
    text-align: left;
}

table{
    width: 100%;
}
td{
    text-align: left;
    vertical-align: top;
}
.tdg
{
    width: 140px;
}
.slideshow{
    margin-left: auto;
    margin-right: auto;
    width: 718px;
    height: 538px;
}
.rozproc{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.rozprocd{
    text-align: center;
    margin: 15px;
    max-width: 100%;
    height: auto;
    display: block;
}
.slideshow{
    max-width:100%;
    max-height:100%;
}
.imgs
{
    width: 100%;
    max-height: 500px;
    max-width: 666px;
    object-fit: contain;
    background-color: lightgray;
}
.centerimg {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    }


.flexroza {
    display: flex;
    flex-direction: column;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1100px;
}
.elementroza {
    background-color: #fff;
    border: 1px solid rgba(0,0,0,.175);
    border-radius: 0.5em;
    flex-direction: column;
    width: 450px;
}

.elementroza, .flexroza {
    font-family: Manrope;
    margin: 15px auto;
    padding: 12px;
}
.podpis {
    text-align: right;
}